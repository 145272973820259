import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import { Helmet } from 'react-helmet';
import logo from "../../static/logo.svg";
import icon from "../../static/icon.svg";

import Menu from "../../static/icons/menu.inline.svg";
import Schedule from "../../static/icons/schedule.inline.svg";
import Money from "../../static/icons/money.inline.svg";
import Targets from "../../static/icons/targets.inline.svg";
import Goodies from "../../static/icons/goodies.inline.svg";

const Layout = ({ path, children }) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const [setActive, setActiveState] = useState("");

  function toggleMenu() {
    setActiveState(setActive === "" ? "active" : "");
  }

	const ListLink = props => (
		<Link to={props.to} className={props.class}>{props.children}</Link>
	)

	return (

    <>

    <Helmet>
      <meta charSet="utf-8" />
      <title>Slate - A planning tool for your freelance business</title>
      <link rel="canonical" href="https://heyslate.com" />
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Slate - Simple planning for freelancers"/>
      <meta name="twitter:description" content="Simple tracking for your schedule, clients, projects and invoices."/>
      <meta name="twitter:image" content={`${site.siteMetadata.siteUrl}/card.jpg?w=1200&amp;h=630&amp;fit=crop`}/>
      <meta property="og:image" content={`${site.siteMetadata.siteUrl}/card.jpg?w=1200&amp;h=630&amp;fit=crop`}/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
    </Helmet>

		<div className="wrap font-regular">

      <div class={`lg:sticky lg:top-0 lg:z-20 border-b px-6 sm:px-12 lg:px-16 xl:px-8 xxl:px-0 ${setActive}`}>

        <nav 
          className={`sm:flex sm:flex-shrink-0 sm:items-center sm:justify-between max-w-6xl mx-auto py-6`}>

          <div className="brand flex items-center justify-between">

            <Link to="/"><img src={logo} alt="Logo" className="h-8" /></Link>

            <button 
              className="text-root outline-none mr-2 sm:hidden"
              onClick={toggleMenu}>
              <Menu />
            </button>

          </div>

          <div className="menu hidden mx-auto justify-center text-root space-x-8 flex-grow sm:flex sm:justify-end">
            <ListLink to="/" class={path.isHomepage ? 'link selected' : 'link'}>Product</ListLink>
            <ListLink to="/faqs" class={path.startsWith('/faqs') ? 'link selected' : 'link'}>FAQs</ListLink>
            <ListLink to="/pricing" class={path.startsWith('/pricing') ? 'link selected' : 'link'}>Pricing</ListLink>
            <Link to="https://app.heyslate.com/login" class="link">Login</Link>
            <Link to="https://app.heyslate.com/register" className="btn btn__sm hover:bg-gray-400 hover:border-gray-400 hover:text-root">
              Sign up
            </Link>
          </div>

        </nav>

      </div>

      <main className="px-6 sm:px-12 lg:px-16 xl:px-8 xxl:px-0">
        {children}
      </main>

      <footer className="text-sm border-t mt-8 sm:mt-16 md:mt-24 lg:mt-32 xl:mt-40">

        <div className="max-w-6xl mx-auto py-6 px-6 sm:px-8 xl:px-0 flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center">

          <figure className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
            <img src={icon} alt="Logo" className="h-6" />
            <span>A planning tool for freelancers.</span>
          </figure>

          <div className="flex flex-col space-y-1 sm:flex-row sm:space-y-0 sm:space-x-8 items-center">
            <ListLink to="/day-rate-calculator" class="link">Calculator</ListLink>
            <ListLink to="/docs" class="link">Docs</ListLink>
            <ListLink to="/blog" class="link">Blog</ListLink>
            <a href="https://twitter.com/_heyslate" class="link">Twitter</a>
            <ListLink to="/privacy" class="link">Privacy</ListLink>
            <ListLink to="/terms" class="link">Terms</ListLink>
          </div>

        </div>

      </footer>

		</div>
    </>

	)

}

export default Layout;